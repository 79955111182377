import { Box, Button, createStyles, Flex, Text } from '@mantine/core';
import { IconCookie } from '@tabler/icons';
import React from 'react';
import CookieConsent, { OPTIONS } from 'react-cookie-consent';
import { t } from 'ttag';
import { GrantMeContainer } from '../../layout/GrantMeContainer';

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.gray[2],
    borderRadius: 8,
    margin: '25px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    left: 0,
    position: 'fixed',
    right: 0,
    zIndex: 999,
    paddingBottom: '24px',
    boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
  },
}));

export const LiitCookieConsent: React.FC = () => {
  const { classes } = useStyles();

  return (
    <CookieConsent
      cookieName={'grantme-cookie-consent'}
      buttonText={t`Godkänn cookies`}
      disableStyles={true}
      location={OPTIONS.BOTTOM}
      containerClasses={classes.container}
      ButtonComponent={(button) => (
        <Button color={'green.6'} mt={16} onClick={() => button.onClick()}>
          {button.children}
        </Button>
      )}
      contentClasses="text-capitalize">
      <GrantMeContainer mb={20}>
        <Flex align={'center'}>
          <Box w={56} h={56} mr={24}>
            <IconCookie size={56} color={'black'} />
          </Box>
          <Text color={'black'} size={14} weight={600}>
            {t`Vi och våra partners använder cookies för att få den här webbplatsen att fungera och för att anpassa innehåll.`}
          </Text>
        </Flex>
      </GrantMeContainer>
    </CookieConsent>
  );
};
