import { UseFormReturnType } from '@mantine/form';
import React from 'react';
import { ApplicantFormFields } from '../../../components/formbuilder/FormRunner';
import { InputClearingNumber } from './InputClearingNumber';
import { getFormLabel } from './formLabels';
import { TextFieldViewer } from './TextFieldViewer';

interface ApplicantClearingNumberFieldProps {
  showViewer: boolean;
  form: UseFormReturnType<ApplicantFormFields>;
}

export const ApplicantClearingNumberField: React.FC<ApplicantClearingNumberFieldProps> = ({
  showViewer,
  form,
}) => {
  const label = getFormLabel('clearingNumber');
  const formValue = form.values.clearingNumber;

  if (showViewer) {
    return <TextFieldViewer label={label} value={formValue} />;
  }

  return <InputClearingNumber label={label} form={form} />;
};
