import { Space, Stack, Title, Text, createStyles } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { ReactElement, ReactNode } from 'react';

const ICON_SIZE = 40;
const STROKE_SIZE = 1.5;

const useStyles = createStyles(() => ({
  iconAndText: {
    display: 'flex',
  },
  iconWrapper: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
}));

interface IconAndTextProps {
  icon: ReactElement;
  title: string;
  text: string;
  animationDelay?: number;
  animate: boolean;
}

export const IconAndText: React.FC<IconAndTextProps> = ({
  icon,
  title,
  text,
  animationDelay = 0,
  animate,
}) => {
  const { classes } = useStyles();

  const withAnimation = (node: ReactNode) => {
    return (
      <motion.div
        animate={{
          opacity: [0, 1],
        }}
        transition={{
          delay: animationDelay,
          duration: 1.5,
          ease: 'easeInOut',
        }}>
        {node}
      </motion.div>
    );
  };

  const content = (
    <div className={classes.iconAndText}>
      <div className={classes.iconWrapper}>
        {React.cloneElement(icon, { size: ICON_SIZE, stroke: STROKE_SIZE })}
      </div>
      <Space w={16} />
      <Stack spacing={0}>
        <Text weight={'bold'} size={'sm'}>
          {title}
        </Text>
        <Text c="dimmed" size={'sm'}>
          {text}
        </Text>
      </Stack>
    </div>
  );

  if (animate) {
    return withAnimation(content);
  }

  return content;
};
