import { UseFormReturnType } from '@mantine/form';
import React from 'react';
import { ApplicantFormFields } from '../../../components/formbuilder/FormRunner';
import { InputBgPg } from './InputBgPg';
import { getFormLabel } from './formLabels';
import { TextFieldViewer } from './TextFieldViewer';
import { WayOfPayment } from '../../../models/WayOfPayment';

interface ApplicantBgPgNumberFieldProps {
  showViewer: boolean;
  form: UseFormReturnType<ApplicantFormFields>;
  wayOfPayment: WayOfPayment.PlusGiro | WayOfPayment.BankGiro;
}

export const ApplicantBgPgNumberField: React.FC<ApplicantBgPgNumberFieldProps> = ({
  showViewer,
  form,
  wayOfPayment,
}) => {
  const fieldName = wayOfPayment === WayOfPayment.PlusGiro ? 'pgNumber' : 'bgNumber';
  const label = getFormLabel(fieldName);
  const formValue = form.values[fieldName];

  if (showViewer) {
    return <TextFieldViewer label={label} value={formValue} />;
  }

  return <InputBgPg label={label} form={form} fieldName={fieldName} />;
};
