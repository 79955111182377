import { PropertyType } from './PropertyType';

// const userLocale =
//   navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

const formatText = (value: string) => value;

const formatCurrency = (value: number) =>
  new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK', maximumFractionDigits: 0 }).format(value);

const formatDate = (value: string) => (value ? new Date(value).toISOString().split('T')[0] : null);

const formatNumber = (value: number) =>
  new Intl.NumberFormat('sv-SE').format(value);

export const formatValue = (value: string | number, type: PropertyType): string | null | number => {
  switch (type) {
    case PropertyType.Text:
      return formatText(value as string);

    case PropertyType.Currency:
      return formatCurrency(value as number);

    case PropertyType.Date:
      return formatDate(value as string);

    case PropertyType.Number:
      return formatNumber(value as number);
  }
};
