import { Group, ActionIcon, useMantineTheme, Popover, Text, GroupPosition } from '@mantine/core';
import { IconAsterisk, IconQuestionMark } from '@tabler/icons';
import React from 'react';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';


interface FormLabelProps {
  element: FormElement;
  mode: FormElementMode;
  position: GroupPosition
}

export const FormLabel:React.FC<FormLabelProps> = ({ element, mode, position }) => {
  const theme = useMantineTheme();
  const { label, required, hideLabel, helpText } = element;

  if (hideLabel) {
    return null;
  }

  const labelText = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);
  
  return (
    <Group spacing={'xs'} position={position} w={'100%'}>
      <Group spacing={4}>
        {labelText}
        {required && <IconAsterisk color={theme.colors.red[5]} size={10} />}
      </Group>
      {mode !== FormElementMode.Editor && helpText && (
        <Popover position={'top'} withArrow shadow="md">
        <Popover.Target>
        <ActionIcon size={16} radius={8} color={theme.colors[theme.primaryColor][7]} variant={'outline'}>
          <IconQuestionMark size={12} />
        </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Text weight={400} color={theme.colors.dark[9]} size="xs" style={{ whiteSpace: 'pre-wrap', maxWidth: '400px' }} >{LanguageHandler.getTextByLanguage(helpText, LanguageCode.sv)}</Text>
        </Popover.Dropdown>
      </Popover>        
      )}
    </Group>
  );
};