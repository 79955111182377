import { createStyles, Group, ScrollArea, Stack, Text } from '@mantine/core';
import React, { useEffect, useRef } from 'react';
import { CompletionComment } from './CompletionComment';
import cls from 'classnames';
import { formatDateTime } from '../../utils/formatDateTime';
import { CompletionCommentType } from './CompletionCommentType';

const useStyles = createStyles((theme) => ({
  commentItem: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    whiteSpace: 'pre-wrap',
  },
  chatBubble: {
    borderRadius: theme.radius.sm,
    maxWidth: '75%',
    padding: '8px 12px',
  },
  chatLeftSide: {
    backgroundColor: theme.white,
  },
  chatRightSide: {
    backgroundColor: theme.colors.blue[1],
  },
  scrollArea: {
    height: '400px',
    backgroundColor: theme.colors.gray[1],
    borderRadius: theme.radius.sm,
    padding: '8px',
  },
  systemMessage: {
    borderTop: `1px solid ${theme.colors.gray[5]}`,
    borderBottom: `1px solid ${theme.colors.gray[5]}`,
    paddingTop: 4,
    paddingBottom: 4,
    marginTop: 8,
    marginBottom: 8,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface CompletionCommentsProps {
  comments: CompletionComment[];
}

export const CompletionComments: React.FC<CompletionCommentsProps> = ({ comments }) => {
  const { classes, theme } = useStyles();

  const viewport = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (viewport.current) {
      viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  const getUserComment = (c: CompletionComment) => {
    return (
      <div
        className={classes.commentItem}
        style={{ alignItems: c.position }}
        key={c.createdUtc.toString()}>
        <Text size={'xs'} weight={700}>
          {c.name}
        </Text>
        <Text size={'xs'} color={'dimmed'}>
          {formatDateTime(c.createdUtc)}
        </Text>
        <div
          className={cls(
            classes.chatBubble,
            c.position === 'start' ? classes.chatLeftSide : classes.chatRightSide,
          )}>
          <Text size={'xs'}>{c.comment}</Text>
        </div>
      </div>
    );
  };

  const getSystemComment = (c: CompletionComment) => {
    return (
      <Group key={`system_${c.createdUtc}`} position={'center'}>
        <div className={classes.systemMessage}>
          <Text color={theme.colors[theme.primaryColor][7]} size={'xs'} weight={'bolder'}>
            {c.comment}
          </Text>
          <Text size={'xs'} color={'dimmed'}>
            {formatDateTime(c.createdUtc)}
          </Text>
        </div>
      </Group>
    );
  };

  return (
    <ScrollArea
      offsetScrollbars
      type={'auto'}
      className={classes.scrollArea}
      viewportRef={viewport}>
      <Stack spacing={'xs'}>
        {comments.map((c) => {
          return c.type === CompletionCommentType.User ? getUserComment(c) : getSystemComment(c);
        })}
      </Stack>
    </ScrollArea>
  );
};
