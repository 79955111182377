import { UseFormReturnType } from '@mantine/form';
import React from 'react';
import { ApplicantFormFields } from '../../../components/formbuilder/FormRunner';

import { InputAccountNumber } from './InputAccountNumber';
import { getFormLabel } from './formLabels';
import { TextFieldViewer } from './TextFieldViewer';

interface ApplicantAccountNumberFieldProps {
  showViewer: boolean;
  form: UseFormReturnType<ApplicantFormFields>;
}

export const ApplicantAccountNumberField: React.FC<ApplicantAccountNumberFieldProps> = ({
  showViewer,
  form,
}) => {
  const label = getFormLabel('accountNumber');
  const formValue = form.values.accountNumber;

  if (showViewer) {
    return <TextFieldViewer label={label} value={formValue} />;
  }

  return <InputAccountNumber label={label} form={form} />;
};
