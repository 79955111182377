import { Stack } from '@mantine/core';
import { FC } from 'react';
import { GrantMeContainer } from '../../layout/GrantMeContainer';

export const FacebookDataDeletionView: FC = () => {
  return (
    <GrantMeContainer>
      <h1>Data Deletion Instruction</h1>
      <p>
        Grantme app uses the Facebook login and we do not save your personal data in our server. As
        per Facebook policy, we have to provide a Data Deletion Instruction.
      </p>

      <p>
        If you want to delete your activities for the Grantme app, you can remove your information
        by following these steps:
      </p>
      <p>
        Go to your Facebook Accounts Setting & Privacy. Click Settings. Look for Apps and Websites
        and you will see all of the apps and websites you linked with your Facebook. Search and
        click Grantme in the search bar. Scroll and click Remove.
      </p>
      <p>
        If you wish to delete user account data, you have to request us to delete your account.
        Please send your request with account registered email to privacy@grantme.se. Your account
        will be deleted and all data will no longer be saved.
      </p>
    </GrantMeContainer>
  );
};
