import { t } from 'ttag';

export const ErrorMessages = {
  IS_REQUIRED: t`Ange ett värde`,
  CONTACT_PERSON_REQUIRED: t`Både Förnamn och Efternamn måste ha ett värde`,
  FILE_UPLOAD_INVALID_FORMAT: t`Filen har ogiltigt format. Giltiga format är JPEG, PDF och PNG.`,
  FILE_UPLOAD_ERROR_DELETING_FILE: t`Kunde inte ta bort filen`,
  ZERO_OR_EMPTY: t`Värdet får inte vara tomt eller 0`,
  FAULTY_IDENTIFIER_FORMAT_PERSON: t`Formatet på personnummer måster vara YYYYMMDDXXXX`,
  FAULTY_IDENTIFIER_FORMAT_ORGANIZATION: t`Formatet på organisationsnummer måste vara 10 siffror`,
  FAULTY_IDENTIFIER_PERSON: t`Felaktigt personnummer`,
  FAULTY_IDENTIFIER_ORGANIZATION: t`Felaktigt organisationsnummer`,
  SELECT_WAY_OF_PAYMENT: t`Välj utbetalningsmetod`,
  FAULTY_CLEARING_AND_ACCOUNTNUMBER: t`Clearing och Kontonummer måst ha värden`,
  BG_NUMBER_MISSING: t`Bankgiro måste ha ett värde`,
  BG_NUMBER_FAULTY: t`Felaktigt bankgironummer`,
  PG_NUMBER_MISSING: t`Plusgiro måste ha ett värde`,
  PG_NUMBER_FAULTY: t`Felaktigt plusgironummer`,
  FAULTY_EMAIL: t`Felaktig e-post`,
  API_CLIENT_ERROR_1001: t`Ansökningsperioden har stängt.`,
};
