import { Grid, Space, Title, Badge } from '@mantine/core';
import React from 'react';
import { t } from 'ttag';
import { ApplicationDetails } from '../../../models/ApplicationDetails';
import { PaymentOptions } from '../../../models/PaymentOptions';
import { getFormLabel } from './formLabels';
import { TextFieldViewer } from './TextFieldViewer';

interface ApplicantFormPersonProps {
  application: ApplicationDetails;
}
export const ApplicantViewerPerson: React.FC<ApplicantFormPersonProps> = ({ application }) => {
  const { isByProxy } = application;

  return (
    <div>
      <Grid gutter={'xs'}>
        <Grid.Col lg={12}>
          <Title order={4}>
            {t`Mottagare`}{' '}
            {isByProxy && (
              <Badge size={'lg'} ml={16}>
                Ansökan gjord via ombud
              </Badge>
            )}
          </Title>
        </Grid.Col>
        <Grid.Col lg={12}>
          <TextFieldViewer
            label={getFormLabel('identityNumber', application.applicantType)}
            value={application.applicantIdentityNumber}
          />
        </Grid.Col>
        <Grid.Col lg={6}>
          <TextFieldViewer
            label={getFormLabel('firstName')}
            value={application.applicantFirstName}
          />
        </Grid.Col>
        <Grid.Col lg={6}>
          <TextFieldViewer label={getFormLabel('lastName')} value={application.applicantLastName} />
        </Grid.Col>
        {!isByProxy && (
          <>
            <Grid.Col lg={6}>
              <TextFieldViewer
                label={getFormLabel('phone')}
                value={application.contactPersonPhone}
              />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextFieldViewer
                label={getFormLabel('email')}
                value={application.contactPersonEmail}
              />
            </Grid.Col>
          </>
        )}

        {application.paymentOptions == PaymentOptions.Default && (
          <>
            <Grid.Col lg={6}>
              <TextFieldViewer
                label={getFormLabel('clearingNumber')}
                value={application.applicantClearingNumber}
              />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextFieldViewer
                label={getFormLabel('accountNumber')}
                value={application.applicantAccountNumber}
              />
            </Grid.Col>
          </>
        )}
      </Grid>
      {isByProxy && (
        <>
          <Space h={32} />
          <Title order={4}>{t`Kontaktperson`}</Title>
          <Space h={'lg'} />
          <Grid gutter={'xs'}>
            <Grid.Col lg={6}>
              <TextFieldViewer
                label={getFormLabel('contactPersonFirstName')}
                value={application.contactPersonFirstName}
              />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextFieldViewer
                label={getFormLabel('contactPersonLastName')}
                value={application.contactPersonLastName}
              />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextFieldViewer
                label={getFormLabel('phone')}
                value={application.contactPersonPhone}
              />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextFieldViewer
                label={getFormLabel('email')}
                value={application.contactPersonEmail}
              />
            </Grid.Col>
          </Grid>
        </>
      )}
    </div>
  );
};
