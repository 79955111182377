import { FC, useEffect, useState } from 'react';
import {
  AppShell,
  ColorScheme,
  ColorSchemeProvider,
  Global,
  MantineProvider,
  MantineThemeOverride,
  useMantineTheme,
} from '@mantine/core';
import { Outlet, useParams } from 'react-router-dom';
import { Organisation } from '../models/Organisation';
import { OrganisationContext } from '../hooks/useOrganisation';
import { MenuHeader } from './MenuHeader';
import { ContentContainer } from './ContentContainer';
import { useApi } from '../hooks/useApi';
import { t } from 'ttag';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import { getCustomColors } from '../utils/customColors';

export const HEADER_HEIGHT = 84;

export const Layout: FC = () => {
  // const [opened, setOpened] = useState(false);
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const { organisationSlug } = useParams();
  const api = useApi();

  const links = [
    {
      label: t`Start`,
      link: `/${organisationSlug}/applicationperiods`,
      requireAuthentication: false,
    },
    {
      label: t`Ansökningar`,
      link: `/${organisationSlug}/applications`,
      requireAuthentication: true,
    },
    {
      label: t`Min sida`,
      link: `/${organisationSlug}/profile`,
      requireAuthentication: true,
    },
  ];

  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const customTheme: MantineThemeOverride = {
    colorScheme,
    colors: getCustomColors(organisation ? organisation.primaryColor : '#a0a0a0'),
    primaryColor: 'brand',
    primaryShade: { light: 7, dark: 4 },
    defaultRadius: 'sm',
    defaultGradient: {
      from: 'orange',
      to: 'red',
      deg: 45,
    },
    fontFamily: `${"'Inter Tight', sans-serif"}`,
    headings: { fontFamily: `${"'Inter Tight', sans-serif"}` },
    dateFormat: 'YYYY-MM-DD',
    datesLocale: 'sv',
    shadows: {
      sm: 'unset',
    },
    components: {
      Stepper: {
        styles: (theme) => ({
          step: {
            icon: {
              background: 'blue',
            },
          },
        }),
      },
      Tabs: {
        styles: (theme) => ({
          tab: {
            // color: theme.colors[theme.primaryColor][7],
            fontWeight: 600,
            // '&[data-active]': {
            //   color: theme.colors[theme.primaryColor][7],
            // },
          },
          tabsList: {
            border: '0px',
          },
        }),
      },
      TextInput: {
        styles: (theme) => ({
          label: {
            color: theme.colors.dark[9],
            fontWeight: 'bold',
            display: 'inline-flex',
          },
        }),
      },
      InputWrapper: {
        styles: (theme) => ({
          label: {
            color: theme.colors.dark[9],
            fontWeight: 'bold',
            display: 'inline-flex',
            gap: '8px',
            width: '100%',
          },
        }),
      },
      // Table: {
      //   styles: (theme) => ({
      //     root: {
      //       thead: {
      //         tr: {
      //           th: {
      //             color: theme.colors[theme.primaryColor][7],
      //           },
      //         },
      //       },
      //     },
      //   }),
      // },
      Button: {
        styles: {
          label: {
            textTransform: 'uppercase',
          },
        },
      },
      Title: {
        styles: (theme) => ({
          root: {
            //color: theme.colors[theme.primaryColor][9],
            // textTransform: 'uppercase',
          },
        }),
      },
    },
  };

  const GlobalStyles = () => {
    return (
      <Global
        styles={(theme) => ({
          body: {
            letterSpacing: '0.5px',
          },
        })}
      />
    );
  };

  const theme = useMantineTheme();

  useEffect(() => {
    const fetchData = async () => {
      if (organisationSlug) {
        const result = await api.getOrganisation(organisationSlug);
        if (result) {
          setOrganisation(result);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <OrganisationContext.Provider value={organisation}>
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={() => setColorScheme}>
        <MantineProvider theme={customTheme} withGlobalStyles withNormalizeCSS withCSSVariables>
          <ModalsProvider>
            <GlobalStyles />
            <NotificationsProvider position="top-right" style={{ top: HEADER_HEIGHT + 12 }}>
              <AppShell
                styles={{
                  root: {
                    overflow: 'hidden',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                  },
                  main: {
                    padding: 0,
                    background: theme.colors.gray[0],
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 0,
                  },
                  body: {
                    overflow: 'hidden',
                    flex: 1,
                  },
                }}
                fixed
                header={<MenuHeader mainLinks={links} />}>
                <ContentContainer>
                  <Outlet />
                </ContentContainer>
              </AppShell>
            </NotificationsProvider>
          </ModalsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </OrganisationContext.Provider>
  );
};
