import { Checkbox, CheckboxProps, Input, TextInput, TextInputProps } from '@mantine/core';
import React, { BaseSyntheticEvent } from 'react';
import { t } from 'ttag';
import {
  formatIdentityNumberSwedish,
} from '../../utils/textFormatters';

type LiitIdentityNumberInputProps = {
  missingIdentityNumberProps?: CheckboxProps;
} & TextInputProps &
React.RefAttributes<HTMLInputElement>;

export const LiitIdentityNumberInput: React.FC<LiitIdentityNumberInputProps> = ({
  missingIdentityNumberProps,
  ...props
}) => {

  const missingChecked = missingIdentityNumberProps?.checked ?? false;

  return (
    <Input.Wrapper label={props.label}>
      <TextInput
        pb={8}
        {...props}
        placeholder={t`ÅÅÅÅMMDDXXXX`}
        onInput={(event: BaseSyntheticEvent<InputEvent>) => {
          const { value } = event.target;

          event.target.value = missingChecked ? value : formatIdentityNumberSwedish(value);
        }}
        label={undefined}
      />
      <Checkbox
          pl={4}
          label={t`Saknar svenskt personnummer`}
          {...missingIdentityNumberProps}
      />
    </Input.Wrapper>
  );
};
