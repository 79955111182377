/* eslint-disable @typescript-eslint/indent */
import { DefaultMantineColor } from '@mantine/core';

// base '#042336'

export const customColors: Partial<
  Record<
    DefaultMantineColor,
    [string, string, string, string, string, string, string, string, string, string]
  >
> = {};

const shadeColor = (color: string, decimal: number): string => {
  const base = color.startsWith('#') ? 1 : 0;

  let r = parseInt(color.substring(base, 3), 16);
  let g = parseInt(color.substring(base + 2, 5), 16);
  let b = parseInt(color.substring(base + 4, 7), 16);

  r = Math.round(r / decimal);
  g = Math.round(g / decimal);
  b = Math.round(b / decimal);

  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;

  const rr = r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16);
  const gg = g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16);
  const bb = b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16);

  return `#${rr}${gg}${bb}`;
};

export const getCustomColors = (primaryColor: string) => {
  const c = customColors;
  c.brand = [
    shadeColor(primaryColor, 0.7),
    shadeColor(primaryColor, 0.6),
    shadeColor(primaryColor, 0.5),
    shadeColor(primaryColor, 0.4),
    shadeColor(primaryColor, 0.3),
    shadeColor(primaryColor, 0.2),
    shadeColor(primaryColor, 0.1),
    primaryColor,
    shadeColor(primaryColor, 1.1),
    shadeColor(primaryColor, 1.2),
  ];
  return c;
};
