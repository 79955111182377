import { TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React, { BaseSyntheticEvent } from 'react';
import { dashSpaceAndNumber, dashSpaceAndNumberCleanUp } from '../../../utils/textFormatters';
import { ApplicantFormFields } from '../../../components/formbuilder/FormRunner';

interface InputBgPgProps {
  label: string;
  fieldName: string;
  form: UseFormReturnType<ApplicantFormFields>,
}

export const InputBgPg: React.FC<InputBgPgProps> = ({ label, fieldName, form }) => {
  return (
    <TextInput
      label={label}
      type={'text'}
      required
      maxLength={16}
      {...form.getInputProps(fieldName)}
      onInput={(event: BaseSyntheticEvent<InputEvent>) => {
        const { value } = event.target;
        const formated = dashSpaceAndNumber(value);
        event.target.value = formated;
      }}
      onBlur={(event) => {
        const { value } = event.target;
        form.setFieldValue(fieldName, dashSpaceAndNumberCleanUp(value));
      }}
    />
  );
};