import React from 'react';
import { Text } from '@mantine/core';

interface TextFieldViewerProps {
  label: string;
  value: string;
}

export const TextFieldViewer: React.FC<TextFieldViewerProps> = ({ label, value }) => {
  return (
    <div>
      <Text size="sm" weight="bold" pb="0" mb="0">
        {label}
      </Text>
      <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
        {value}
      </Text>
    </div>
  );
};
