import { FC, useEffect, useState } from 'react';
import {
  Title,
  Stack,
  Grid,
  Space,
  useMantineTheme,
  TypographyStylesProvider,
} from '@mantine/core';
import { ApplicationPeriod, ApplicationPeriodStatus } from '../../models/ApplicationPeriod';
import { useOrganisationContext } from '../../hooks/useOrganisation';
import { useApi } from '../../hooks/useApi';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { ApplicationPeriodCard } from './application-period-card';
import { t } from 'ttag';
import LanguageHandler from '../../utils/languageHandler';
import { getLocale } from '../../i18nInit';
import { LanguageCode } from '../../models/LanguageCode';
import { useParams } from 'react-router-dom';

export const ApplicationPeriodView: FC = () => {
  const [applicationPeriod, setApplicationPeriod] = useState<ApplicationPeriod | undefined>(undefined);
  const organisation = useOrganisationContext();
  const api = useApi();
  const { applicationPeriodId } = useParams();

  useEffect(() => {
    if (organisation) {
      const fetchData = async () => {
        const result = await api.getApplicationPeriods(organisation.id);
        setApplicationPeriod(result.find(x => x.id === applicationPeriodId));
      };

      fetchData();
    }
  }, [organisation]);

  if (!organisation || !applicationPeriod) {
    return <div />;
  }

  return (
    <>
      <GrantMeContainer>
        <Stack>
            <Grid>
                <Grid.Col key={applicationPeriod.id}>
                  <ApplicationPeriodCard
                    grant={applicationPeriod}
                    organisation={organisation}
                    isUpcoming={false}
                  />
                </Grid.Col>
            </Grid>
        </Stack>
      </GrantMeContainer>
    </>
  );
};
