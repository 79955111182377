import React, { FC, useEffect, useState } from 'react';
import {
  Title,
  Stack,
  Badge,
  Text,
  Accordion,
  Group,
  Flex,
  ActionIcon,
  Divider,
  createStyles,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useOrganisationContext } from '../../hooks/useOrganisation';
import { useApi } from '../../hooks/useApi';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { Application, ApplicationType } from '../../models/Application';
import { ApplicationStatus } from '../../models/ApplicationStatus';
import { t } from 'ttag';
import { IconTrash } from '@tabler/icons';
import { openConfirmModal } from '@mantine/modals';
import { CompletionStatus } from '../../models/CompletionStatus';
import Formatter from '../../utils/formatter';
import { ApplicationStatuses } from './ApplicationStatuses';

const useStyles = createStyles((theme) => ({
  rowHover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
  applicationItem: {
    flex: 1,
    padding: '8px 16px',
  },
}));

export const ApplicationList: FC = () => {
  const api = useApi();
  const organisation = useOrganisationContext();
  const [applications, setApplications] = useState<Application[]>();
  const navigate = useNavigate();
  const { organisationSlug } = useParams();
  const { classes, theme, cx } = useStyles();

  const applicationStatuses = ApplicationStatuses(theme);

  const refreshApplications = async () => {
    if (organisation) {
      const response = await api.getApplications(organisation.id);
      setApplications(response);
    }
  };

  const hasClaimablePayments = () => {
    return applications?.some((x) => x.hasClaimablePayments);
  };

  useEffect(() => {
    if (organisation) {
      const fetchData = async () => {
        await refreshApplications();
      };

      fetchData();
    }
  }, [organisation]);

  if (!organisation || !applications) {
    return <div />;
  }

  const deleteApplication = async (id: string) => {
    await api.deleteApplication(organisation.id, id);
    await refreshApplications();
  };

  const deleteApplicationModal = (id: string) => {
    return openConfirmModal({
      title: <Title order={4}>{t`Ta bort ansökan`}</Title>,
      children: (
        <Stack>
          <Text size={'sm'}>{t`Är du säker på att du vill ta bort den påbörjade ansökan?`}</Text>
        </Stack>
      ),
      labels: { confirm: 'Ta bort', cancel: 'Avbryt' },
      onCancel: () => console.log('close'),
      onConfirm: () => deleteApplication(id),
    });
  };

  const getBadge = (count: number) => {
    return (
      <Badge sx={{ width: 20, height: 20, pointerEvents: 'none' }} variant="filled" size="sm" p={0}>
        {count}
      </Badge>
    );
  };

  const ongoingApplications = applications.filter(
    (x) =>
      x.type == ApplicationType.Application &&
      x.status == ApplicationStatus.Draft &&
      x.completionStatus === CompletionStatus.None,
  );
  const requisitionApplications = applications.filter(
    (x) => x.type == ApplicationType.Application && x.hasClaimablePayments,
  );
  const submittedApplications = applications.filter(
    (x) =>
      x.type == ApplicationType.Application &&
      (x.status === ApplicationStatus.Submitted ||
        x.status === ApplicationStatus.Approved ||
        x.status === ApplicationStatus.Declined) &&
      x.completionStatus !== CompletionStatus.Requested,
  );

  const completionRequestedApplications = applications.filter(
    (x) =>
      x.status === ApplicationStatus.Submitted && x.completionStatus === CompletionStatus.Requested,
  );

  const applicationReports = applications.filter((x) => x.type == ApplicationType.Report);

  const getStandardApplicationItem = (a: Application, path: string, showStatus = true) => {
    return (
      <React.Fragment key={a.id}>
        <Divider key={`${a.id}_divider`} />
        <Flex
          className={cx(classes.rowHover, classes.applicationItem)}
          direction={'column'}
          onClick={() =>
            // navigate(`/${organisationSlug}/applications/${id}/requisition`);
            navigate(`/${organisationSlug}/applications/${a.id}${path}`)
          }>
          <Text size={'sm'} weight={600}>
            {a.applicationPeriodTitle}
          </Text>
          <Group position={'apart'} spacing={4} pt={4}>
            <Group spacing={'xs'}>
              <Text size={'xs'} weight={600}>{t`Dirarienr:`}</Text>
              <Text size={'xs'}>{a.numberFormatted}</Text>
            </Group>
            <Group spacing={'xs'}>
              <Text size={'xs'} weight={600}>{t`Inlämnad:`}</Text>
              <Text size={'xs'}>{Formatter.formatDateString(a.modifiedUtc)}</Text>
            </Group>
            {showStatus && (
              <Group spacing={'xs'}>
                <Text size={'xs'} weight={600}>{t`Status:`}</Text>
                <Text size={'xs'}>{applicationStatuses[a.status].title}</Text>
              </Group>
            )}
          </Group>
        </Flex>
      </React.Fragment>
    );
  };

  return (
    <GrantMeContainer>
      <Stack>
        <Title order={3}>{t`Ansökningar`}</Title>
        <Accordion
          variant={'separated'}
          defaultValue={'ongoing'}
          styles={(aTheme) => ({
            panel: {
              backgroundColor: aTheme.white,
            },
            control: {
              backgroundColor: aTheme.white,
              '&:hover': {
                backgroundColor: aTheme.white,
              },
            },
            content: {
              padding: 0,
            },
          })}>
          <Accordion.Item value={'ongoing'}>
            <Accordion.Control>
              <Group>
                <Text size={'sm'} weight={600}>{t`Pågående`}</Text>
                {getBadge(ongoingApplications.length)}
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              {ongoingApplications.map((a) => (
                <React.Fragment key={a.id}>
                  <Divider key={`${a.id}_divider`} />
                  <Flex justify={'center'} align={'center'} className={classes.rowHover} pr={16}>
                    <Flex
                      className={classes.applicationItem}
                      onClick={() => navigate(`/${organisationSlug}/applications/${a.id}/edit`)}
                      direction={'column'}>
                      <Text size={'sm'} weight={600}>
                        {a.applicationPeriodTitle}
                      </Text>
                      <Group pt={4} spacing={'xs'} style={{ cursor: 'pointer' }}>
                        <Text size={'xs'} weight={600}>{t`Ändrad:`}</Text>
                        <Text size={'xs'}>{Formatter.formatDateString(a.modifiedUtc)}</Text>
                      </Group>
                    </Flex>
                    <ActionIcon
                      key={`${a.id}_actionbutton_delete`}
                      onClick={() => deleteApplicationModal(a.id)}>
                      <IconTrash size={20} color={theme.colors[theme.primaryColor][9]} />
                    </ActionIcon>
                  </Flex>
                </React.Fragment>
              ))}
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value={'submitted'}>
            <Accordion.Control>
              <Group>
                <Text size={'sm'} weight={600}>{t`Inlämnade`}</Text>
                {getBadge(submittedApplications.length)}
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              {submittedApplications.map((a) => getStandardApplicationItem(a, ''))}
            </Accordion.Panel>
          </Accordion.Item>
          {requisitionApplications.length > 0 && (
            <Accordion.Item value={'requisition'}>
              <Accordion.Control>
                <Group>
                  <Text size={'sm'} weight={600}>{t`Rekvirering`}</Text>
                  {getBadge(requisitionApplications.length)}
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                {requisitionApplications.map((a) => getStandardApplicationItem(a, '/requisition'))}
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {completionRequestedApplications.length > 0 && (
            <Accordion.Item value={'completionRequested'}>
              <Accordion.Control>
                <Group>
                  <Text size={'sm'} weight={600}>{t`Komplettering`}</Text>
                  {getBadge(completionRequestedApplications.length)}
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                {completionRequestedApplications.map((a) =>
                  getStandardApplicationItem(a, '/edit', false),
                )}
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {applicationReports.length > 0 && (
            <Accordion.Item value={'reporting'}>
              <Accordion.Control>
                <Group>
                  <Text size={'sm'} weight={600}>{t`Återrapportering`}</Text>
                  {getBadge(applicationReports.length)}
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                {applicationReports.map((a) =>
                  getStandardApplicationItem(
                    a,
                    a.status === ApplicationStatus.Draft ? '/edit' : '',
                    true,
                  ),
                )}
              </Accordion.Panel>
            </Accordion.Item>
          )}
        </Accordion>
      </Stack>
    </GrantMeContainer>
  );
};
