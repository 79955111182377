import { isPresent } from 'utilitype';
import { ValidationResult } from '../../../../models/ValidationResult';
import { ErrorMessages } from '../../../../utils/errorMessages';
import Validator from '../../../../utils/validators';
import { FormElement } from '../../models/FormElement';
import { FormElementTypedTextBox } from '../../models/FormElementTypedTextBox';
import { TextBoxInputType } from './TextBoxInputType';

export const validateTypedTextBox = (element: FormElement & any): ValidationResult => { 
  const typedElement = element as FormElementTypedTextBox;

  const { required, inputType } = typedElement;

  if (required && !isPresent(element.value)) {
    return { isValid: false, errorMessage: ErrorMessages.IS_REQUIRED };
  }

  if (inputType === TextBoxInputType.Email || inputType === TextBoxInputType.ContactPersonEmail) {
    const isOk = Validator.validateEmail(element.value);
    if (!isOk) {
      return { isValid: false, errorMessage: ErrorMessages.FAULTY_EMAIL };
    }
  }

  return { isValid: true, errorMessage: '' };
};