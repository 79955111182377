import { t } from 'ttag';
import { ApplicantType } from '../../../models/ApplicantType';

export const getFormLabel = (fieldName: string, type?: ApplicantType) => {
  const labels: { [key: string]: string } = {
    firstName: t`Förnamn`,
    lastName: t`Efternamn`,
    organizationName: t`Organisationsnamn`,
    phone: t`Telefonnummer`,
    email: t`E-postadress`,
    clearingNumber: t`Clearingnummer`,
    accountNumber: t`Kontonummer`,
    pgNumber: t`PlusGiro`,
    bgNumber: t`BankGiro`,
    identityNumber: type === ApplicantType.Organization ? t`Organisationsnummer` : t`Personnummer`,
    contactPersonFirstName: t`Förnamn`,
    contactPersonLastName: t`Efternamn`,
  };

  return labels[fieldName];
};
