import { Box, Flex, Grid, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons';
import { FC } from 'react';
import { t } from 'ttag';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';

export const ViewerContactPersonOrganization: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const { isByProxy, contactPersonFirstName, contactPersonLastName } = element.value;

  if (!isByProxy) {
    return null;
  }

  return (
      <Grid gutter={'lg'}>
        <Grid.Col lg={6}>
          <Text size="md" weight="bold" pb="0" mb="0">{t`Förnamn`}</Text>
          <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
            {contactPersonFirstName}
          </Text>
        </Grid.Col>
        <Grid.Col lg={6}>
          <Text size="md" weight="bold" pb="0" mb="0">{t`Förnamn`}</Text>
          <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
            {contactPersonLastName}
          </Text>
        </Grid.Col>
      </Grid>
  );
};
