import { Box, Flex, Grid, Group, Text } from '@mantine/core';
import React from 'react';
import { t } from 'ttag';
import { isMissing } from 'utilitype';
import { FormElementComponent } from '../../FormElementComponent';
import { FormElementMode } from '../../FormElementMode';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { FormElement } from '../../models/FormElement';
import { LanguageCode } from '../../../../models/LanguageCode';
import Formatter from '../../../../utils/formatter';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElementType } from '../../models/FormElementType';
import { FormElementTypedTextBox } from '../../models/FormElementTypedTextBox';
import { TextBoxInputType } from '../TypedTextBox/TextBoxInputType';
import { FormElementRepeater } from '../../models/FormElementRepeater';

export const ViewerRepeater: React.FC<ViewerFormElementComponentProps> = ({ element, number }) => {
  const groups = element.value as FormElement[][];
  const repeater = element as FormElementRepeater;

  if (isMissing(groups)) {
    return (
      <ViewerInputWrapper number={number} element={element}>
        {t`Inga värden satta`}
      </ViewerInputWrapper>
    );
  }

  const getSummary = () => {
    const hasNumberFields = element.template
      .filter((x) => x.type === FormElementType.TypedTextBox)
      .some((x) => (x as FormElementTypedTextBox).inputType === TextBoxInputType.Number);

    if (!hasNumberFields) {
      return null;
    }

    return (
      <Flex direction={'column'} align={'end'} pr={12}>
        {element.template
          .map((el, elementIndex) => {
            if (
              el.type === FormElementType.TypedTextBox &&
              (el as FormElementTypedTextBox).inputType === TextBoxInputType.Number
            ) {
              return (
                <Group key={el.id} w={250} position={'apart'}>
                  <Text size={'sm'} weight={500}>
                    {LanguageHandler.getTextByLanguage(el.label, LanguageCode.sv)}
                  </Text>
                  <Text size={'sm'} weight={500}>
                    {Formatter.formatNumber(
                      (element.value as FormElement[][]).reduce(
                        (a, b) => a + Number(b[elementIndex].value),
                        0,
                      ),
                    )}
                  </Text>
                </Group>
              );
            }
          })
          .filter((x) => x)}
      </Flex>
    );
  };

  return (
    <ViewerInputWrapper number={number} element={element}>
      {groups.map((group, groupIndex) => (
        <Box key={`repeater_${number}_group_${groupIndex}`}>
          <Grid>
            {group.map((childElement) => {
              if (repeater.labelsOnFirstGroup && groupIndex > 0) {
                childElement.hideLabel = true;
              }
              return (
                <Grid.Col
                  md={12}
                  lg={childElement.size ? childElement.size : 12}
                  key={`repeater_${number}_group_${groupIndex}_element_${childElement.id}`}>
                  <FormElementComponent element={childElement} mode={FormElementMode.Viewer} />
                </Grid.Col>
              );
            })}
          </Grid>
        </Box>
      ))}
      {repeater.sumNumberFields && groups.length > 0 && getSummary()}
      {groups.length === 0 && (
        <Text color={'dimmed'} size={'sm'}>
          {t`Saknas`}
        </Text>
      )}
    </ViewerInputWrapper>
  );
};
