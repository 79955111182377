import { Grid, Select, Title } from '@mantine/core';
import React from 'react';
import { t } from 'ttag';
import { WayOfPayment } from '../../../../models/WayOfPayment';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';
import { InputAccountNumber } from './InputAccountNumber';
import { InputBgPg } from './InputBgPg';
import { InputClearingNumber } from './InputClearingNumber';
import { ViewerWayOfPayment } from './ViewerWayOfPaymentElement';
import { WayOfPaymentElementValue } from './WayOfPaymentValue';

type WayOfPaymentElementProps = {
  element: FormElement;
} & FormElementComponentProps;

export const WayOfPaymentElement: React.FC<WayOfPaymentElementProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerWayOfPayment number={number} element={element} />;
  }

  const value = element.value as WayOfPaymentElementValue;

  const { wayOfPayment, bgNumber, pgNumber, clearingNumber, accountNumber } = value;

  return (
    <Grid gutter={'xs'}>
      <Grid.Col span={12}>
        <Title order={4}>{t`Utbetalningsinformation`}</Title>
      </Grid.Col>

      <Grid.Col lg={4}>
        <Select
          label="Typ"
          error={error}
          required
          value={wayOfPayment.toString()}
          placeholder={t`Utbetalningssätt`}
          disabled={mode === FormElementMode.Editor}
          data={[
            { value: WayOfPayment.BankAccount.toString(), label: 'Bankkonto' },
            { value: WayOfPayment.PlusGiro.toString(), label: 'PlusGiro' },
            { value: WayOfPayment.BankGiro.toString(), label: 'BankGiro' },
          ]}
          onChange={(event) => {
            if (onChange) {
              onChange({
                wayOfPayment: Number(event),
                pgNumber: '',
                bgNumber: '',
                clearingNumber: '',
                accountNumber: '',
              });
            }
          }}
        />
      </Grid.Col>
      {wayOfPayment === WayOfPayment.BankAccount && (
        <>
          <Grid.Col lg={4}>
            <InputClearingNumber
              label={t`Clearingnummer`}
              error={!!error}
              value={clearingNumber}
              onChange={(newClearingNumber) => {
                if (onChange) {
                  onChange({ ...value, clearingNumber: newClearingNumber });
                }
              }}
            />
          </Grid.Col>
          <Grid.Col lg={4}>
            <InputAccountNumber
              label={'Kontonummer'}
              error={!!error}
              value={accountNumber}
              onChange={(newAccountNumber) => {
                if (onChange) {
                  onChange({ ...value, accountNumber: newAccountNumber });
                }
              }}
            />
          </Grid.Col>
        </>
      )}
      {(wayOfPayment === WayOfPayment.PlusGiro || wayOfPayment === WayOfPayment.BankGiro) && (
        <Grid.Col lg={8}>
          <InputBgPg
            error={!!error}
            wayOfPayment={wayOfPayment}
            value={wayOfPayment === WayOfPayment.PlusGiro ? pgNumber : bgNumber}
            onChange={(newValue) => {
              if (onChange) {
                if (wayOfPayment === WayOfPayment.PlusGiro) {
                  onChange({ ...value, pgNumber: newValue });
                } else {
                  onChange({ ...value, bgNumber: newValue });
                }
              }
            }}
          />
        </Grid.Col>
      )}
    </Grid>
  );
};
