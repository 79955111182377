import { Stack } from '@mantine/core';
import { FC } from 'react';
import { GrantMeContainer } from '../../layout/GrantMeContainer';

export const StartView: FC = () => {
  return (
    <GrantMeContainer> 
      <Stack style={{ height: 600 }} justify="center" align="center">
        <img src="/grantme_dark_transparent.png" width="300" />
      </Stack>
    </GrantMeContainer>
  );
};
