import { DefaultMantineColor, MantineTheme } from '@mantine/core';
import { ApplicationStatus } from '../../models/ApplicationStatus';

export interface StatusInformation {
  title: string;
  color: string;
  colorKey: DefaultMantineColor;
}

export const ApplicationStatuses = (
  theme: MantineTheme,
): Record<ApplicationStatus, StatusInformation> => {
  return {
    [ApplicationStatus.Approved]: {
      title: 'Beviljad',
      color: theme.colors.green[5],
      colorKey: 'green.5',
    },
    [ApplicationStatus.Declined]: {
      title: 'Nekad',
      color: theme.colors.red[3],
      colorKey: 'red.3',
    },
    [ApplicationStatus.Draft]: {
      title: 'Pågående',
      color: theme.colors.yellow[5],
      colorKey: 'yellow.5',
    },
    [ApplicationStatus.Submitted]: {
      title: 'Inlämnad',
      color: theme.colors.gray[6],
      colorKey: 'gray.6',
    },
    // [ApplicationStatus.SubmittedCompletionRequested]: {
    //   title: 'Komplettering begärd',
    //   color: theme.colors.gray[6],
    //   colorKey: 'gray.6',
    // },
    // [ApplicationStatus.SubmittedCompletionResponded]: {
    //   title: 'Inlämnad med komplettering',
    //   color: theme.colors.yellow[5],
    //   colorKey: 'yellow.5',
    // },
  };
};
