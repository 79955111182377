import { DateTime } from 'luxon';
import { t } from 'ttag';
import { isPresent } from 'utilitype';

export default class Formatter {
  static formatDateString(value: string | null): string  {
    return isPresent(value) ? DateTime.fromISO(value).toFormat('yyyy-MM-dd') : '';
  }

  static formatDateTimeString(value: string | null): string {
    return isPresent(value) ? DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm') : '';
  }

  static formatDate(value: Date | null): string {
    return isPresent(value) ? DateTime.fromJSDate(value).toFormat('yyyy-MM-dd') : '';
  }

  static formatNumber(value: number): string {
    return new Intl.NumberFormat('sv-SE').format(value);
  }

  static formatBoolean(value: boolean): string {
    return value ? t`Ja` : t`Nej`;
  }

  static formatCurrency(value: number | null): string {
    if (!value) return '-';

    return Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: 'SEK',
      maximumFractionDigits: 0,
    }).format(value);
  }
}
