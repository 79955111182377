import { Textarea, TextInput } from '@mantine/core';
import { FC } from 'react';
import { INPUT_MAX_LENGTH } from '../../../../App';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { LiitNumberInput } from '../../../LiitNumberInput/LiitNumberInput';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';
import { FormElementTypedTextBox } from '../../models/FormElementTypedTextBox';
import { FormLabel } from '../Common/FormLabel';
import { TextBoxInputType } from './TextBoxInputType';
import { ViewerTypedTextBox } from './ViewerTypedTextBox';

type TypedTextBoxProps = {
  element: FormElement & FormElementTypedTextBox
} & FormElementComponentProps;

export const TypedTextBox: FC<TypedTextBoxProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerTypedTextBox number={number} element={element} />;
  }

  const { value, description, label, required, hideLabel } = element;

  const labelText = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);
  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);
  const text = (value as string) ?? '';
  const { inputType } = element;

  if (inputType === TextBoxInputType.RequestedAmount || inputType === TextBoxInputType.Number) {
    return <LiitNumberInput
    hideControls
    disabled={mode === FormElementMode.Editor}
    description={descriptionText}
    label={hideLabel ? undefined : <FormLabel element={element} mode={mode} position={'apart'} />}
    placeholder={hideLabel === undefined ? undefined : labelText}
    required={required}
    withAsterisk={false}
    value={value ? Number(value) : undefined}
    precision={0}
    error={error}
    maxLength={INPUT_MAX_LENGTH}
    onChange={(event) => {
      if (onChange) {
        onChange(event?.toString() ?? null);
      }
    }}
  />;
  }

  return (
    <TextInput
      disabled={mode === FormElementMode.Editor}
      description={descriptionText}
      label={hideLabel ? undefined : <FormLabel element={element} mode={mode} position={'apart'} />}
      placeholder={hideLabel === undefined ? undefined : labelText}
      required={required}
      withAsterisk={false}
      value={text}
      error={error}
      maxLength={INPUT_MAX_LENGTH}
      onChange={(event) => {
        if (onChange) {
          onChange(event.currentTarget.value ?? '');
        }
      }}
    />
  );
};
