const settings: { [key: string]: { [key: string]: string } } = {
  // Production
  'https://apply.grant-me.com': {
    SERVER_URL: 'https://api.grant-me.com/client',
    REDIRECT_URI: 'https://apply.grant-me.com',
    SCOPES:
      'https://grantmeb2c.onmicrosoft.com/a181b30b-d387-4e5d-94cf-2dd6459d1556/clientapi.full',
    CLIENTID: 'ad5a19fc-b586-42e4-b09b-08c710ad90c7',
    AUTHORITY: 'https://grantmeb2c.b2clogin.com/grantmeb2c.onmicrosoft.com/B2C_1_signupsignin',
    KNOWN_AUTHORITY: 'grantmeb2c.b2clogin.com',
  },

  'https://apply.grantme.se': {
    SERVER_URL: 'https://api.grantme.se/client',
    REDIRECT_URI: 'https://apply.grantme.se',
    SCOPES:
      'https://grantmeb2c.onmicrosoft.com/a181b30b-d387-4e5d-94cf-2dd6459d1556/clientapi.full',
    CLIENTID: 'ad5a19fc-b586-42e4-b09b-08c710ad90c7',
    AUTHORITY: 'https://grantmeb2c.b2clogin.com/grantmeb2c.onmicrosoft.com/B2C_1_signupsignin',
    KNOWN_AUTHORITY: 'grantmeb2c.b2clogin.com',
  },

  // Test
  'https://test-client.grant-me.com': {
    SERVER_URL: 'https://grantme-api-test.azurewebsites.net/client',
    REDIRECT_URI: 'https://test-client.grant-me.com',
    SCOPES:
      'https://grantmetestb2c.onmicrosoft.com/e8fd0bf7-dfe6-405a-9ab0-4d2b9b27b58f/clientapi.full',
    CLIENTID: '16b166aa-7a25-413d-bab0-baf6f8567138',
    AUTHORITY:
      'https://grantmetestb2c.b2clogin.com/grantmetestb2c.onmicrosoft.com/B2C_1_client_signupsignin',
    KNOWN_AUTHORITY: 'grantmetestb2c.b2clogin.com',
  },

  'https://test-client.grantme.se': {
    SERVER_URL: 'https://test-api.grantme.se/client',
    REDIRECT_URI: 'https://test-client.grantme.se',
    SCOPES:
      'https://grantmetestb2c.onmicrosoft.com/e8fd0bf7-dfe6-405a-9ab0-4d2b9b27b58f/clientapi.full',
    CLIENTID: '16b166aa-7a25-413d-bab0-baf6f8567138',
    AUTHORITY:
      'https://grantmetestb2c.b2clogin.com/grantmetestb2c.onmicrosoft.com/B2C_1_client_signupsignin',
    KNOWN_AUTHORITY: 'grantmetestb2c.b2clogin.com',
  },

  // Development
  'http://localhost:3002': {
    SERVER_URL: 'https://localhost:7153/client',
    REDIRECT_URI: 'http://localhost:3002/',
    SCOPES:
      'https://grantmetestb2c.onmicrosoft.com/e8fd0bf7-dfe6-405a-9ab0-4d2b9b27b58f/clientapi.full',
    CLIENTID: '16b166aa-7a25-413d-bab0-baf6f8567138',
    AUTHORITY:
      'https://grantmetestb2c.b2clogin.com/grantmetestb2c.onmicrosoft.com/B2C_1_client_signupsignin',
    KNOWN_AUTHORITY: 'grantmetestb2c.b2clogin.com',
  },
};

export const getSettings = (): { [key: string]: string } => {
  const rootUrl = `${window.location.protocol}//${window.location.host}`;
  return settings[rootUrl];
};
