import { PaymentOptions } from './PaymentOptions';

export interface ApplicationPeriod {
  id: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  paymentOptions: PaymentOptions;
  isActive: boolean;
  status: ApplicationPeriodStatus;
}

export enum ApplicationPeriodStatus {
  Upcoming = 0,
  Ongoing = 1,
  Completed = 2,
  Archived = 3,
}
