import { Text } from '@mantine/core';
import { FC } from 'react';
import { FormElementOption } from '../../models/FormElementOption';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';

export const ViewerDropdown: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const value = element.value as string;
  const optionsValue = LanguageHandler.getTextByLanguage(element.options.find((x: FormElementOption) => x.id === value)?.label, LanguageCode.sv);

  return (
    <ViewerInputWrapper number={number} element={element}>
      <Text size="sm">{optionsValue}</Text>
    </ViewerInputWrapper>
  );
};
