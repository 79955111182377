import { isPresent } from 'utilitype';

export type Bank = {
  name: string;
  clearingRanges: { from: number; to: number }[];
};

export const banks: Bank[] = [
  { name: 'Sveriges Riksbank', clearingRanges: [{ from: 1000, to: 1099 }] },
  {
    name: 'Nordea',
    clearingRanges: [
      { from: 1100, to: 1199 },
      { from: 1400, to: 2099 },
      { from: 3000, to: 3399 },
      { from: 3410, to: 4999 },
      { from: 9500, to: 9549 },
      { from: 9960, to: 9969 },
    ],
  },
  {
    name: 'Danske Bank',
    clearingRanges: [
      { from: 1200, to: 1399 },
      { from: 2400, to: 2499 },
      { from: 9180, to: 9189 },
    ],
  },
  { name: 'Ålandsbanken', clearingRanges: [{ from: 2300, to: 2399 }] },
  {
    name: 'Länsförsäkringar Bank',
    clearingRanges: [
      { from: 3400, to: 3409 },
      { from: 9020, to: 9029 },
      { from: 9060, to: 9069 },
    ],
  },
  {
    name: 'SEB',
    clearingRanges: [
      { from: 5000, to: 5999 },
      { from: 9120, to: 9124 },
      { from: 9130, to: 9149 },
    ],
  },
  { name: 'Handelsbanken', clearingRanges: [{ from: 6000, to: 6999 }] },
  {
    name: 'Swedbank',
    clearingRanges: [
      { from: 7000, to: 8999 },
      { from: 9300, to: 9349 },
    ],
  },
  { name: 'Citibank', clearingRanges: [{ from: 9040, to: 9049 }] },
  { name: 'Calyon Bank', clearingRanges: [{ from: 9080, to: 9089 }] },
  { name: 'RBS', clearingRanges: [{ from: 9090, to: 9099 }] },
  { name: 'Nordnet Bank', clearingRanges: [{ from: 9100, to: 9109 }] },
  { name: 'Skandiabanken', clearingRanges: [{ from: 9150, to: 9169 }] },
  { name: 'IKANO Banken', clearingRanges: [{ from: 9170, to: 9179 }] },
  {
    name: 'DNB Bank',
    clearingRanges: [
      { from: 9190, to: 9199 },
      { from: 9260, to: 9269 },
    ],
  },
  { name: 'Marginalen Bank', clearingRanges: [{ from: 9230, to: 9239 }] },
  { name: 'SBAB Bank', clearingRanges: [{ from: 9250, to: 9259 }] },
  { name: 'ICA Banken', clearingRanges: [{ from: 9270, to: 9279 }] },
  { name: 'Resurs Bank', clearingRanges: [{ from: 9280, to: 9289 }] },
  { name: 'Pareto Öhman', clearingRanges: [{ from: 9380, to: 9389 }] },
  { name: 'Landshypotek', clearingRanges: [{ from: 9390, to: 9399 }] },
  { name: 'Forex Bank', clearingRanges: [{ from: 9400, to: 9449 }] },
  { name: 'Santander Consumer Bank', clearingRanges: [{ from: 9460, to: 9469 }] },
  { name: 'Fortis Bank', clearingRanges: [{ from: 9470, to: 9479 }] },
  { name: 'Avanza Bank', clearingRanges: [{ from: 9550, to: 9569 }] },
  { name: 'Sparbanken Syd', clearingRanges: [{ from: 9570, to: 9579 }] },
  { name: 'Exchange Finans Europe', clearingRanges: [{ from: 9580, to: 9589 }] },
  { name: 'Erik Penser Bankaktiebolag', clearingRanges: [{ from: 9590, to: 9599 }] },
  { name: 'Volvofinans Bank', clearingRanges: [{ from: 9610, to: 9619 }] },
  { name: 'Bank of China (Luxembourg)', clearingRanges: [{ from: 9620, to: 9629 }] },
  { name: 'Lån & Spar Bank', clearingRanges: [{ from: 9630, to: 9639 }] },
  { name: 'Nordax Bank', clearingRanges: [{ from: 9640, to: 9649 }] },
  { name: 'MedMera Bank', clearingRanges: [{ from: 9650, to: 9659 }] },
  { name: 'Svea Bank', clearingRanges: [{ from: 9660, to: 9669 }] },
  { name: 'JAK Medlemsbank', clearingRanges: [{ from: 9670, to: 9679 }] },
  { name: 'Bluestep Finans', clearingRanges: [{ from: 9680, to: 9689 }] },
  { name: 'Folkia', clearingRanges: [{ from: 9690, to: 9699 }] },
  { name: 'Ekobanken', clearingRanges: [{ from: 9700, to: 9709 }] },
  { name: 'Netfonds Bank (ub)', clearingRanges: [{ from: 9720, to: 9729 }] },
  { name: 'FTCS', clearingRanges: [{ from: 9770, to: 9779 }] },
  { name: 'Klarna Bank', clearingRanges: [{ from: 9780, to: 9789 }] },
  { name: 'Privatgirot', clearingRanges: [{ from: 9860, to: 9869 }] },
  { name: 'Nasdaq-OMX', clearingRanges: [{ from: 9870, to: 9879 }] },
  { name: 'Riksgälden', clearingRanges: [{ from: 9880, to: 9899 }] },
  { name: 'Teller Branch Norway', clearingRanges: [{ from: 9951, to: 9951 }] },
  { name: 'Bankernas Automatbolag', clearingRanges: [{ from: 9952, to: 9952 }] },
  { name: 'Teller Branch Sweden', clearingRanges: [{ from: 9953, to: 9953 }] },
  { name: 'Kortaccept Nordic AB', clearingRanges: [{ from: 9954, to: 9954 }] },
  { name: 'Kommuninvest', clearingRanges: [{ from: 9955, to: 9955 }] },
  { name: 'VP Securities A/S', clearingRanges: [{ from: 9956, to: 9956 }] },
];

const swedbankSpecial: number[] = [
  80184, 80309,	80325, 80507,	80556, 80606, 80770, 80796,	81034, 81059, 81141, 81299,	81380, 81430,	81471, 81489,	
  81505, 81539,	81562, 81588, 81646, 81661,	81695, 81828,	81836, 82016,	82149, 82172,	82222, 82347,	82396, 82420,	
  82503, 82578,	82644, 82701, 82842, 82867, 82891, 82958, 82990, 83048, 83055, 83139, 83147, 83212, 83246, 83279,
  83311, 83360, 83469, 83519, 83535, 83543, 83568, 83592, 83683, 83832, 83816, 83840, 83881, 83931, 84012, 84053,
  84178, 84202, 84228, 84244, 84319, 84343, 84400, 84525, 84556, 84640, 84806,
];

export default class Banks {
  static getBankName(value: number | string): string {
    const checkValue = typeof value === 'number' ? value : Number(value);

    if (isNaN(checkValue)) {
      return '';
    }

    const result = banks.find((b) =>
      b.clearingRanges.some((ranges) => ranges.from <= checkValue && ranges.to >= checkValue),
    );

    if (!result && swedbankSpecial.some(x => x === checkValue)) {
      return 'Swedbank';
    }

    return isPresent(result) ? result.name : '';
  }
}
