import React, { useState } from 'react';
import { Button, Drawer, Group, Stack, Textarea, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { CompletionComments } from './CompletionComments';
import { useApi } from '../../hooks/useApi';
import { ApplicationDetails } from '../../models/ApplicationDetails';
import { isMissing } from 'utilitype';
import { ApplicationStatus } from '../../models/ApplicationStatus';
import { t } from 'ttag';
import { CompletionStatus } from '../../models/CompletionStatus';
import { LiitDrawer } from '../LiitDrawer/LiitDrawer';

interface RequestCompletionProps {
  organisationId: string;
  application: ApplicationDetails;
  onCompletionRequested: () => void;
  showCompletionComments: boolean;
  onCloseCompletionComments: () => void;
}

export const RequestCompletion: React.FC<RequestCompletionProps> = ({
  organisationId,
  application,
  onCompletionRequested,
  showCompletionComments: showCompletionsMessages,
  onCloseCompletionComments: onCloseCompletionMessages,
}) => {
  const api = useApi();
  const form = useForm({
    initialValues: {
      completionComment: '',
    },
    validate: {
      completionComment: (value) => (!value ? 'Du måste ange en kommentar' : null),
    },
  });

  const save = async (values) => {
    if (isMissing(values) || application.completionStatus !== CompletionStatus.Requested) {
      //TODO: Lägg till felmeddelande
      return;
    }

    const response = await api.respondToCompletion(
      organisationId,
      application.id,
      values.completionComment,
    );

    if (response.ok) {
      onCompletionRequested();
    }

    form.setFieldValue('completionComment', '');
  };

  return (
    <Drawer
      size={'90%'}
      styles={() => ({
        drawer: {
          maxWidth: '400px',
        },
      })}
      title={<Title order={4}>{t`Komplettering`}</Title>}
      position={'right'}
      padding={12}
      opened={showCompletionsMessages}
      onClose={() => onCloseCompletionMessages()}>
      <form onSubmit={form.onSubmit((values) => save(values))} style={{ width: '100%' }}>
        <Stack>
          <CompletionComments comments={application.completionComments} />
          <Textarea
            minRows={3}
            placeholder="Fyll i komplettering och skicka in."
            label="Kommentar"
            {...form.getInputProps('completionComment')}
            disabled={application.completionStatus != CompletionStatus.Requested}
          />
          <Group position="right">
            <Button
              type="submit"
              disabled={application.completionStatus != CompletionStatus.Requested}>
              {t`Skicka meddelande`}
            </Button>
          </Group>
        </Stack>
      </form>
    </Drawer>
  );
};
