import { createContext, useContext } from 'react';
import { Organisation } from '../models/Organisation';

export const OrganisationContext = createContext<Organisation | null>(null);

export const useOrganisationContext = () => {
  const organisation = useContext(OrganisationContext);

  return organisation;
};
