import { TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React, { BaseSyntheticEvent } from 'react';
import { spaceAndNumber } from '../../../utils/textFormatters';
import { ApplicantFormFields } from '../../../components/formbuilder/FormRunner';

interface InputAccountNumberProps {
  label: string;
  form: UseFormReturnType<ApplicantFormFields>;
}

export const InputAccountNumber: React.FC<InputAccountNumberProps> = ({ label, form }) => {
  return (
    <TextInput
      label={label}
      type={'text'}
      maxLength={16}
      required
      {...form.getInputProps('accountNumber')}
      onInput={(event: BaseSyntheticEvent<InputEvent>) => {
        const { value } = event.target;
        event.target.value = spaceAndNumber(value);
      }}
      onBlur={(event) => {
        const { value } = event.target;
        form.setFieldValue('accountNumber', value.trim());
      }}
    />
  );
};
