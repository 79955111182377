import { Checkbox } from '@mantine/core';
import { FC } from 'react';
import { FormElementOption } from '../../models/FormElementOption';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { ViewerCheckboxGroup } from './CheckboxGroupViewer';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElement } from '../../models/FormElement';
import { FormLabel } from '../Common/FormLabel';

const mapOptions = (disabled: boolean, id: string, option: FormElementOption) => {
  const { label, id: optionsId } = option;
  const optionLabel = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);

  return (
    <Checkbox disabled={disabled} key={`${id}_cb_${optionsId}`} value={optionsId} label={optionLabel} />
  );
};

type CheckboxGroupProps = {
  
  element: FormElement
} & FormElementComponentProps;

export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerCheckboxGroup number={number} element={element} />;
  }

  const { description, required, id, options,  hideLabel } = element;

  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);

  const values = element.value && Array.isArray(element.value) ? (element.value as string[]) : [];

  return (
    <Checkbox.Group
      value={values}
      description={descriptionText}
      label={hideLabel ? undefined : <FormLabel element={element} mode={mode} position={'left'} />}
      required={required}
      withAsterisk={false}
      orientation="vertical"
      spacing="sm"
      error={error}
      onChange={(changedValues: string[]) => {
        if (onChange) {
          onChange(changedValues);
        }
      }}>
      {options.map((option) => mapOptions(mode === FormElementMode.Editor, id, option))}
    </Checkbox.Group>
  );
};
