import { Text } from '@mantine/core';
import { FC } from 'react';
import Formatter from '../../../../utils/formatter';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';

export const ViewerDatePickerElement: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const text = element.value as string;
  return (
    <ViewerInputWrapper number={number} element={element}>
      <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>{Formatter.formatDateString(text)}</Text>
    </ViewerInputWrapper>
  );
};
