import { ApplicationStatus } from './ApplicationStatus';
import { CompletionStatus } from './CompletionStatus';

export interface Application {
  id: string;
  number: string;
  status: ApplicationStatus;
  type: ApplicationType;
  completionStatus: CompletionStatus;
  applicationPeriodTitle: string;
  createdUtc: string;
  modifiedUtc: string;
  submittedUtc: string;
  hasClaimablePayments: boolean;
  numberFormatted?: string;
}



export enum ApplicationType {
  Application = 0,
  Report = 1,
}
