import { Text } from '@mantine/core';
import React from 'react';
import { isMissing } from 'utilitype';
import Formatter from '../../../../utils/formatter';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { FormElementTypedTextBox } from '../../models/FormElementTypedTextBox';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import { TextBoxInputType } from './TextBoxInputType';

export const ViewerTypedTextBox: React.FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const typedElement = element as FormElementTypedTextBox;
  const { inputType } = typedElement;
  const formatedText = (): string => {
    if (isMissing(element.value)) {
      return '';
    }

    if (inputType === TextBoxInputType.RequestedAmount || inputType === TextBoxInputType.Number) {
      const toFormat = typeof(typedElement.value) === 'number' ? typedElement.value : Number(typedElement.value);
      return Formatter.formatNumber(toFormat);
    }

    return typedElement.value as string;
  };
  
  return (
    <ViewerInputWrapper number={number} element={element}>
      <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>{formatedText()}</Text>
    </ViewerInputWrapper>
  );
};
