import { Paper, Stack, Title, Grid } from '@mantine/core';
import { FC, ReactNode, useEffect, useState } from 'react';
import { FormModel } from './models/FormModel';
import { FormPage } from './models/FormPage';
import { FormElement } from './models/FormElement';
import { FormElementComponent } from './FormElementComponent';
import { FormElementMode } from './FormElementMode';
import { t } from 'ttag';
import { LanguageCode } from '../../models/LanguageCode';
import LanguageHandler from '../../utils/languageHandler';
import { FormElementType } from './models/FormElementType';
import { ContactPersonValue } from './FormElements/ContactPerson/ContactPersonValue';

interface RunnerProps {
  json: string;
  showPageLabels: boolean;
}

export const FormViewer: FC<RunnerProps> = ({ json }) => {
  const [model, setModel] = useState<FormModel>({ pages: [] } as FormModel);

  useEffect(() => {
    const result: FormModel = JSON.parse(json);
    setModel(result);
  }, [json]);

  const renderElements = (page: FormPage, startNumber: number) => {
    return page.elements.map((element: FormElement, index: number) => {
      const key = `element_${element.id}_${index}`;
      if (
        element.type === FormElementType.ContactPerson &&
        !(element.value as ContactPersonValue).isByProxy
      ) {
        return null;
      }

      return (
        <Grid.Col md={12} lg={element.size ? element.size : 12} key={key}>
          <FormElementComponent
            number={startNumber + index}
            element={element}
            mode={FormElementMode.Viewer}
          />
        </Grid.Col>
      );
    });
  };

  return (
    <Stack>
      {model.pages.map((page: FormPage, index) => {
        const pageNumber = index + 1;
        const elements = renderElements(page, pageNumber);
        const pageLabel = LanguageHandler.getTextByLanguage(page.label, LanguageCode.sv);

        return (
          <Paper shadow="sm" p="md" key={pageLabel + pageNumber + elements.length} withBorder>
            <Title order={3} pb={'lg'} color={'dimmed'}>
              {pageLabel ?? t`Sida ${pageNumber}`}
            </Title>
            <Grid>{elements}</Grid>
          </Paper>
        );
      })}
    </Stack>
  );
};
