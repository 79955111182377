import { initLocale } from './i18nInit';
import { Router } from './router';
import { LiitCookieConsent } from './components/LiitCookieConsent/LiitCookieConsent';

export const INPUT_MAX_LENGTH = 200;

const App = () => {
  initLocale();

  return (
    <>
      <Router />
      <LiitCookieConsent />
    </>
  );
};

export default App;
