import { Configuration, RedirectRequest } from '@azure/msal-browser';
import { getSettings } from './Settings';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: getSettings().CLIENTID,
    authority:getSettings().AUTHORITY,
    knownAuthorities: [getSettings().KNOWN_AUTHORITY],
    redirectUri: getSettings().REDIRECT_URI,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [getSettings().SCOPES],
  extraQueryParameters: {
    ui_locales: 'sv',
  },
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};
