import { Button } from '@mantine/core';
import { FC, useState } from 'react';
import { isMissing } from 'utilitype';
import { useApi } from '../../../../hooks/useApi';
import { UploadedFile } from '../../../../models/UploadedFile';
import { useOrganisationContext } from '../../../../hooks/useOrganisation';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import { LiitDrawer } from '../../../LiitDrawer/LiitDrawer';
import { PDFViewer } from '../../../PdfViewer/PDFViewer';
import { toBlob } from '../../../../utils/toBlob';
import { ImageViewer } from '../../../ImageViewer/ImageViewer';

const isPdf = (type: string): boolean => type === 'application/pdf';

const PADDING_SPACE = 24;
const DRAWER_WIDTH = 800 + PADDING_SPACE;

export const ViewerFileBox: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const fileElement = element.value as UploadedFile;

  const api = useApi();
  const organisation = useOrganisationContext();
  const [opened, setOpened] = useState(false);
  const PDF_CONTENT_TYPE = 'application/pdf';

  if (isMissing(fileElement)) {
    return (
      <ViewerInputWrapper number={number} element={element}>
        {'Ingen fil uppladdad'}
      </ViewerInputWrapper>
    );
  }

  const { id, type, filename } = fileElement;

  const getData = async (): Promise<string | null> => {
    if (id && organisation) {
      const arrayBuffer = await api.downloadFile(organisation.id, id);

      return URL.createObjectURL(toBlob(new Uint8Array(arrayBuffer), PDF_CONTENT_TYPE));
    }

    return null;
  };

  if (id && organisation) {
    return (
      <ViewerInputWrapper number={number} element={element}>
        <Button
          size={'xs'}
          onClick={() => {
            setOpened(true);
          }}>
          Visa
        </Button>

        <LiitDrawer
          opened={opened}
          onClose={() => setOpened(false)}
          padding={isPdf(type) ? 0 : 'xl'}
          size={DRAWER_WIDTH}>
          {isPdf(type) && <PDFViewer getData={getData} filename={filename} />}
          {isPdf(type) === false && (
            <ImageViewer getData={getData} filename={filename}></ImageViewer>
          )}
        </LiitDrawer>
      </ViewerInputWrapper>
    );
  }

  return <></>;
};
