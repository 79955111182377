import { Stack } from '@mantine/core';
import { FC } from 'react';
import { GrantMeContainer } from '../../layout/GrantMeContainer';

export const TermsOfServiceView: FC = () => {
  return (
    <GrantMeContainer>
      <h1>TJÄNSTEAVTAL </h1>
      <h3>1. Bakgrund </h3>
      Dessa tjänste- och användarvillkor (”Villkoren”), inklusive dess bilaga 1 -
      Personuppgiftsbiträdesavatal, gäller för Grantme ABs (”Grantme”), org nr XXXXXX-XXXX,
      tillhandahållande av Grantme (”Tjänsten”). Tjänsten är ett abonnemang och tillhandahålls
      online som en software-as-a-service (”SaaS”). Användandet av Tjänsten sker av organisationers,
      företags, föreningars och stiftelser (gemensamt ”Kunder”) befattningshavare/företrädare (såsom
      styrelseledamöter och VD) (”Användare”). För nyttjande av tjänsten krävs dels att Kund och
      Grantme ingått avtal om Betalabonnemang och i anslutning därtill bekräftat dessa Villkor, dels
      att dess Användare bekräftat dessa Villkor i samband med registrering som Användare av
      Betalabonnemang. I detta Avtal betyder ”vi”, ”oss” eller ”Grantme” Grantme AB och ”ni”, ”er”
      eller ”kund” ni som kund
      <h3>2. Tjänsten </h3>I enlighet med dessa villkor lämnar Grantme, under Avtalstiden, Kunden en
      ej överförbar, icke-exklusiv och ej licensierbar rätt att nyttja Tjänsten för dess Användare
      enligt den omfattnings som avtalats (”Licensen”).
      <h4>2.1 REGISTRERING </h4>
      Användare ges tillgång till Tjänsten genom att logga in och registrera sig via de
      registreringsalternativ som erbjuds.
      <h4>2.2 ÅTKOMST </h4>
      Åtkomst till Tjänsten sker genom de åtkomstalternativ som Grantme från tid till annan
      informerar om via Tjänsten. Användaren är medveten om och samtycker till att Tjänsten även kan
      användas tillsammans med externa tjänster som erbjuds av bolag som Grantme samarbetar med.
      Grantme ansvarar inte för tillhandahållandet av sådana externa tjänster eller för innehållet
      eller resultatet av sådana externa tjänster. Grantme kan löpande komma att introducera och
      erbjuda Tilläggstjänster. Tilläggstjänster avser tjänster vars nyttjande är frivillig för
      Kund/Användare. Tillgång till Tilläggstjänster som tillhandahålls utan kostnad för och som
      inte kräver samtycke erhålls automatiskt. Tillgång till tilläggstjänster som erbjuds mot en
      avgift eller som i övrigt kräver samtycke förutsätter dels att Kund och Grantme ingått avtal
      om abonnemang av sådan Tilläggstjänster, dels att Användare bekräftat eventuella tillkommande
      villkor som gäller för sådana Tilläggstjänster. När begreppet ”Tjänsten” används nedan avses
      även sådana Tilläggstjänster.
      <h4>2.3 Grantmes kommunikation med användare </h4>
      Grantme kan komma att skicka e-post, SMS eller kommunicera med Användare via tjänsten gällande
      information om ändringar i Tjänsten samt information om nya funktioner. Denna kommunikation
      betraktas som en integrerad del av Tjänsten vilket innebär att den inte kan väljas bort.
      Kunden/Användare ska skicka meddelanden till Grantme till följande e-postadress:
      feedback@grantme.com
      <h3>3. Ändring i Tjänsten, ändringar i Villkoren</h3> Grantme har rätt att när som helst göra
      ändringar i Tjänsten. Ändringar kan t.ex. avse förändringar av den tekniska lösningen för
      Tjänsten och/eller Tjänstens design och utformning och kan medföra att vissa funktioner
      upphör, ändras eller tillkommer och/eller att de tekniska förutsättningarna för åtkomst av
      Tjänsten ändras. Grantme äger rätt att när som helt ändra dessa Villkor. Kunden ska informeras
      om ändringar innan de träder i kraft. Avgiftsändringar vilka ska meddelas enligt punkt 5
      nedan. Om Kunden inte accepterar ändringarna i Villkoren ska Kunden säga upp Abonnemanget
      enligt punkt 6 nedan.
      <h3>4. Tillgänglighet </h3>
      Grantme förbehåller sig rätten att när som helst tillfälligt avbryta tillhandahållandet av
      Tjänsten för serviceändamål, t.ex. buggrättningar, underhåll och uppgraderingar. Grantme
      garanterar inte och ansvarar heller inte för att Tjänsten är fri från eventuella fel,
      förseningar och/eller avbrott.
      <h3>5. Avgifter och betalningsvillkor </h3>
      Avgifter och betalningssätt avseende Betalabonnemang följer av från tid till annan gällande
      prislista. Eventuell avgiftsjustering ska kommuniceras senast 14 dagar före
      Abonnemangsperiodens slut. I sådant fall gäller den nya avgiften vid start av efterföljande
      Abonnemangsperiod.
      <h3>6. Avtalstid och uppsägning </h3>
      Abonnemangsperioden anges i samband med ingående av abonnemang. Endera Part kan när som helst
      avsluta pågående abonnemang och därmed Tjänsten till upphörande vid utgången av
      abonnemangsperioden genom ett meddelande enligt dessa Villkor. Om uppsägning inte sker i
      enlighet med dessa Villkor förlängs abonnemanget automatiskt med samma abonnemangsperiod.
      Därutöver äger endera Part rätt att utan uppsägningstid avsluta pågående abonnemang och därmed
      Tjänsten i händelse av att den andra Parten begått ett väsentligt brott mot dessa Villkor.
      Återbetalning av erlagd abonnemangsavgift vid eventuell återstående abonnemangstid sker inte,
      såvida inte Kunds uppsägning av abonnemanget är hänförligt till Grantmes väsentliga brott mot
      dessa Villkor.
      <h3>7. Kunddata, Kunds och Användares ansvar</h3>
      <h4> 7.1 KUNDDATA</h4> De uppgifter och det material som Kund/Användare för in i Tjänsten,
      samt information och data rörande Kund och Användare benämns i det följande ”Kunddata”. Kunden
      äger och ansvarar för all Kunddata som laddas upp till Tjänsten. Ansvaret inkluderar
      personuppgifter för vilka Kunden är personuppgiftsansvarig. I den mån Kunden hanterar
      personuppgifter i tjänsten på ett sådant sätt som att Grantme betraktas som ett
      personuppgiftsbiträde är det Kundens ansvar att teckna ett Biträdesavtal med Grantme enligt
      Personuppgiftsbiträdesavatal (bilaga 1)
      <h4>7.2 ANSVAR</h4> Kunden är ansvarig för Kundens och dess Användares efterlevnad av
      villkoren som anges i dessa Villkor inklusive Bilagor. Kunden/Användaren ansvarar för att
      personlig och användarspecifik information såsom användaridentitet, mobilt Bank-ID och
      lösenord eller andra handlingar, certifikat eller enheter som kan nyttjas för åtkomst till
      Tjänsten förvaras på ett betryggande sätt och inte används av eller avslöjas för någon
      obehörig. Om en Kund/Användare misstänker att sådan information kommit någon obehörig
      tillhanda eller på annat sätt missbrukas, är Kunden/Användaren skyldig att omedelbart vidta
      åtgärd för att begränsa åtkomst till Tjänsten och informera Grantme om den uppkomna
      situationen. Användare ansvarar för aktiviteter som Användaren vidtar i Tjänsten. Användare
      ansvarar således bl.a. för överföring av information och dokument som vidtas av Användaren
      inom ramen för Tjänsten. Användaren ansvarar vidare för att den information som Användaren
      sparar i Tjänsten inte står i strid med svensk lagstiftning. Användare får inte använda
      Tjänsten på sådant sätt att Grantme eller annan drabbas av olägenhet eller skada. Användare
      får således t.ex. inte via Tjänsten hantera information som gör intrång i tredje parts
      rättigheter eller som kan uppfattas som stötande eller förargelseväckande såsom t.ex.
      framställningar med hatiskt, hotfullt eller pornografiskt innehåll eller som uppmanar till
      våldshandlingar eller hets mot folkgrupp. Användare får inte: bereda obehöriga tillgång till
      Tjänsten, licensiera, distribuera, hyra ut, leasa, överföra, överlåta, eller på annat sätt
      förfoga över Tjänsten; kopiera eller använda Tjänsten för andra ändamål än vad som framgår av
      Avtalet; eller utföra reverse engineering, dekompilera eller ta isär Tjänsten eller själv söka
      efter programvarans källkod eller affärshemligheter som är knutna till Tjänsten, med undantag
      för de fall och i sådan omfattning tillämplig lagstiftning uttryckligen förbjuder sådan
      begränsning. Brott mot denna punkt 7 utgör väsentligt avtalsbrott.
      <h3>8. Behandling av personuppgifter</h3>
      Inom ramen för tillhandahållandet av Tjänsten behandlar Grantme, såsom personuppgiftsansvarig,
      en användares personuppgifter enligt Grantmes vid var tid gällande Integritetspolicy.
      <h3>9. Ansvar och ansvarsbegränsningar</h3> Grantme ansvarar inte för Användares förlust av
      data eller eventuella skador som uppkommer på grund av att Användaren lämnat felaktig
      information vid registrering. Grantme ersätter endast Användare för styrkta och skäliga
      direkta kostnader som uppkommit som en direkt följd av vårdslöshet från Grantmes sida. Grantme
      ersätter dock inte under några omständigheter Kund eller Användare för indirekta kostnader,
      skador eller förluster, såsom t.ex. utebliven vinst eller andra följdskador, såvida inte
      Grantme har handlat uppsåtligt eller grovt vårdslöst. Grantmes sammanlagda
      ersättningsskyldighet enligt dessa Villkor kan inte överstiga ett belopp motsvarande vad den
      Kund har erlagt under en period av tolv (12) månader närmast föregående den handling eller
      underlåtenhet som har gett upphov till skadan. Krav på ersättning av Grantme ska, för att
      kunna göras gällande, framställas inom en (1) månad efter det att skadan upptäckts.
      <h3>10. Force majeure</h3> Grantme ansvarar inte för förlust eller skada som ni kan komma att
      drabbas av om våra möjligheter att uppfylla Avtalet har förhindrats eller väsentligen
      försvårats av omständigheter som vi eller vår leverantör inte rimligen kunnat kontrollera
      eller förvänta oss. Exempel på sådant kan vara arbetskonflikt, krig, uppror eller upplopp,
      pandemi eller epidemi, mobilisering eller oförutsedda militärinkallelser, rekvisition, beslag,
      valutarestriktioner, export- eller importrestriktioner, lockout eller annan arbetskonflikt,
      jordbävning, blixtnedslag, eldsvåda, översvämning eller vattenskada, allmän varuknapphet eller
      knapphet i fråga om transportmedel, lagstiftning eller myndighetsrestriktioner.
      <h3>11. Sekretess</h3>
      Grantme ska iaktta sekretess beträffande information och dokument som hanteras via Tjänsten
      enligt från tid till annan av Grantme antagna tillämpliga dataskydds- och säkerhetspolicy
      (bilaga 2)
      <h3>12. Immateriella rättigheter</h3>
      Grantme innehar samtliga rättigheter, innefattande men inte uteslutande alla immateriella
      rättigheter, till Tjänsten, inklusive men inte begränsat till den tekniska lösningen, kod,
      användargränssnitt, know how och eventuellt innehåll däri som Grantme tillhandahåller inom
      ramen för Tjänsten, tillkommer Grantme eller dess rättighetsinnehavare och är skyddade enligt
      lag. Inga sådana rättigheter övergår till Kund eller Användaren som ett led i
      tillhandahållandet av Tjänsten.
      <h3>13. Lagval och tvistlösning</h3> Dessa användarvillkor regleras av svensk lag. Tvist
      rörande tolkning eller tillämpning av dessa Villkor ska Grantme avgörs i allmän domstol.
    </GrantMeContainer>
  );
};
