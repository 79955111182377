import { Stack } from '@mantine/core';
import { FC } from 'react';
import { GrantMeContainer } from '../../layout/GrantMeContainer';

export const PrivacyView: FC = () => {
  return (
    <GrantMeContainer>
      <h1>INTEGRITETSPOLICY</h1>
      GÄLLANDE FRÅN 1 Juli 2022
      <br />
      <p>
        För att kunna tillhandahålla dig Tjänsten och kunna informera om förändringar och nyheter i
        densamma behöver vi spara och behandla personuppgifter om dig, så som ditt namn,
        personnummer, din e-postadress och i vilka bolag du har uppdrag i.{' '}
      </p>
      <p>
        Vi får dina uppgifter från offentliga källor såsom Bolagsverket, från
        autentiseringslösningar såsom BankID och från dig själv när du registrerar dig på Tjänsten.
        Vilka personuppgifter som vi behandlar tydliggörs i tabellen nedan där “källa” står för
        varifrån informationen kommer. Den rättsliga grunden för behandlingen är ditt avtal med
        Grantme.{' '}
      </p>
      <table>
        <tr>
          <th>Personuppgift</th>
          <th>Källa</th>
          <th>Ändamål</th>
        </tr>
        <tr>
          <td>Personnummer</td>
          <td>Användaren själv</td>
          <td>
            Ditt personnummer behandlas av Grantme för att på ett säkert sätt kunna identifiera att
            du är du.
          </td>
        </tr>
        <tr>
          <td>E-postadress</td>
          <td>Användaren själv</td>
          <td>
            Din e-postadress behandlas för att Grantme ska kunna skicka meddelanden till dig om att
            ny information finns tillgänglig tjänsten samt för att skicka information till dig
            gällande de av Grantmes tjänster som du använder.
          </td>
        </tr>
        <tr>
          <td>Mobiltelefon- nummer</td>
          <td>Användaren själv</td>
          <td>
            Ditt mobiltelefonnummer behandlas för att Grantme ska kunna skicka meddelanden till dig
            om att ny information finns tillgänglig tjänsten samt för att skicka information till
            dig gällande de av Grantmes tjänster som du använder. Mobiltelefonnumret kan också komma
            att användas för säkerhetsrelaterade ändamål.
          </td>
        </tr>
        <tr>
          <td>Namn</td>
          <td>Användaren själv</td>
          <td>
            Ditt namn behandlas för att Grantme och andra användare på tjänsten som du samverkar med
            ska kunna identifiera dig.
          </td>
        </tr>
        <tr>
          <td>IP-adress</td>
          <td>Grantme (vid användning av Grantmes tjänster)</td>
          <td>
            Din IP-adress, som även registreras vid utskick av e-post till dig, behandlas för att
            Grantme ska kunna genomföra erforderliga felsökningar, motverka intrång och incidenter,
            för bevismaterial inom ramen för dokumentsignering samt för Grantmes produktutveckling
            (med avseende på säkerheten för produkterna).
          </td>
        </tr>
      </table>
      <p>
        Grantme vidtar alla lämpliga legala, tekniska och organisatoriska åtgärder för att
        säkerställa att dina personuppgifter hanteras säkert och med en tillräcklig skyddsnivå.
        Detta gäller såväl internt på Grantme som vid överföring av dina personuppgifter till eller
        delning av uppgifter med sådana utvalda tredje parter som Grantme samarbetar med för
        tillhandahållande av Grantmes tjänster till dig. Endast personer som behöver behandla dina
        personuppgifter i enlighet med ändamålen som beskrivs i tabellen ovan har tillgång till dina
        personuppgifter.{' '}
      </p>
      <p>
        Dina personuppgifter kommer aldrig att säljas. Dina personuppgifter kommer inte heller att
        vidareförmedlas eller på annat sätt användas för andra syften än de som vi informerar dig om
        i tabellen ovan.
      </p>
      <p>
        Behandling av dina personuppgifter inom ramen för Grantmes tjänster sker primärt inom
        EU/EES, men kan också ske i USA. Oavsett var behandlingen geografiskt genomförs så sker den
        alltid i enlighet med GDPR. Vi kan även komma att dela dina personuppgifter med tredje part,
        förutsatt att vi är skyldiga att göra så enligt lag.
      </p>
      <p>
        Personuppgifter som behandlas för att utföra Grantmes avtalsrättsliga åtaganden gentemot dig
        behandlas så länge du använder Grantmes tjänster. Vid avslutande av Grantmes tjänster
        raderas samtliga personuppgifter som vi behandlat om dig inom ramen för tjänsterna med
        följande undantag:
      </p>
      <p>
        Du har rätt att, i enlighet med gällande dataskyddslagstiftning, när som helst begära
        tillgång till de personuppgifter som behandlas om dig. Du har också rätt att få felaktiga
        personuppgifter om dig rättade, att begära radering av dina personuppgifter eller
        begränsning av vår behandling av dina personuppgifter, utöva din rätt till dataportabilitet
        och invända mot behandlingen av dina personuppgifter.
      </p>
      <p>
        Om behandling baseras på samtycke har du rätt att när som helst dra tillbaka ditt samtycke
        till behandlingen. Om du vill utöva någon av dina rättigheter, vänligen kontakta oss via
        kontaktuppgifterna nedan. Du har även rätt att när som helst inge klagomål till tillämplig
        tillsynsmyndighet om du anser att dina personuppgifter behandlas i strid med tillämplig
        dataskyddslagstiftning.
      </p>
      <p>
        {' '}
        Personuppgiftsansvarig är Grantme AB. Du har rätt att kontakta oss om du vill ha ut
        information om de uppgifter vi har om dig eller begära radering av dina uppgifter. Detta gör
        du enklast genom att kontakta oss på privacy@grantme.com. Om du har klagomål på vår
        behandling av dina personuppgifter har du rätt att inge klagomål till tillsynsmyndigheten
        Datainspektionen.
      </p>
    </GrantMeContainer>
  );
};
